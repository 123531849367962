import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production'

const store = new Vuex.Store({
  modules: {},
  strict: debug,
  plugins: debug ? [Vuex.createLogger()] : [],
  getters: {}
});

export default store;
