import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "@/plugins/i18n";
import { authGuard } from "@tt/vue-auth0";

Vue.use(VueRouter);

const routes = [
  {
    path: "/routes",
    name: "RoutesView",
    menuTitle: i18n.tc("routes.title", 2),
    menuIcon: "mdi-truck-delivery-outline",
    showIcon: false,
    component: () => import("@/views/RoutesView"),
    active: true
  },
  {
    path: "/targets",
    name: "TargetsView",
    menuTitle: i18n.tc("targets.title", 2),
    menuIcon: "mdi-target",
    showIcon: false,
    component: () => import("@/views/TargetsView"),
    active: true
  }
];

// create router
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// auth check
router.beforeEach(authGuard);

export default router;
