import Vue from "vue";
import Vuetify from "vuetify/lib";
import { preset } from '@tt/vue-cli-plugin-vuetify-preset-tt/preset'

export default new Vuetify({
  preset,
  options: { customProperties: true },
  theme: { light: true },
  icons: {
    iconfont: 'mdi'
  }
});
